
import content from '@/mixins/content'
import pageTransitionGuard from '@/mixins/pageTransitionGuard'
import pageTransition from '@/mixins/pageTransition'

export default {
  layout: 'default',
  name: 'PagesRouteHome',
  scrollToTop: false,

  mixins: [content, pageTransitionGuard, pageTransition],

  mounted() {
    this.$store.commit('nav/setMountedKey')
  },
}
